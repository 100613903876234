// @flow
import './polyfills';

import React from 'react';
import ReactDOM from 'react-dom';

import { onLocalizationInit } from './ui/localization/i18n';

const rootEl = document.getElementById('root');
if (!rootEl) {
  throw new Error('There is no a root element.');
}

onLocalizationInit.then(async () => {
  const module = await import('./app');
  const App = module.default;
  ReactDOM.render(<App />, rootEl);
});
