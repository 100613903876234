export const EN_LOCALE = 'en';
export const EN_LOCALE_TITLE = 'English';
export const DEFAULT_LOCALE = EN_LOCALE;
export const REGION = {
  RU: 'RU',
  US: 'US',
  FR: 'FR',
};

export const REGION_EMAIL_SUPPORT = {
  [REGION.US]: 'info@bot.one',
  [REGION.RU]: 'support_formone@pravo.tech',
  [REGION.FR]: 'info@bot.one',
};

export const DATE_FORMAT = {
  DATETIME_SHORT: {
    DEFAULT: 'M/D/YY h:mm A',
    ru: 'DD.MM.YYYY HH:mm',
  },

  DATETIME_SHORT_WITH_SEC: {
    DEFAULT: 'DD.MM.YYYY, HH:mm:ss',
    ru: 'DD.MM.YYYY, HH:mm:ss',
  },

  DATE_SHORT: {
    DEFAULT: 'DD.MM.YYYY',
    ru: 'DD.MM.YYYY',
  },
};
